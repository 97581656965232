.blog__container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.newblog__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 50px;
    backdrop-filter: blur(30px);
}

.newblog__form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 60%;
}

.newblog__error {
    color: red;
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.newblog__form div {
    display: flex;
    flex-direction: column;
}

.newblog__label {
    font-size: 1.2rem;
    margin-bottom: 3px;
}

.newblog__input {
    padding: 4px;
    font-size: 1rem;
    margin-bottom: 10px;
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: transparent;
}

.newblog__textarea {
    padding: 4px;
    font-size: 1rem;
    margin-bottom: 16px;
    border: 1px solid #fff;
    border-radius: 4px;
    resize: none;
    height: 100px;
    background: transparent;
}

.newblog__select {
    margin-bottom: 20px;
    text-align: left;
}

.ant-select-selector {
    padding: 10px !important;
    display: flex;
    flex-direction: row !important;
}

.ant-select-selection-overflow {
    display: flex;
    flex-direction: row !important;
}

.quill__editor {
    margin-top: 1rem;
    height: 300px;
    font-size: 20px;
}

.ql-toolbar {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    color: #fff !important;
    background-color: #ccc;
}

.ql-editor {
    font-size: 16px !important;
    color: #fff !important;
}

.newblog__submit {
    display: flex;
    align-items: center;
    margin-top: 100px;
}

@media only screen and (max-width: 768px) {
    .blog__container {
        flex-direction: column;
        align-items: center;
    }
    .newblog__container {
        width: 100%;
    }
}