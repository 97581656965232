.adminLogs {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    color: #fff;
}

.adminlogs__container {
    width: 30vw;
    padding: 10px;
    background-color: #18151a;
}

.adminlogs__h3 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Manrope", sans-serif;
    color: #eee;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .adminlogs__container {
        width: 100%;
    }
}