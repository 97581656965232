.searchbar {
    display: inline-flex;
    gap: 11px;
}

.searchbar_input {
    padding: 10px 30px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    border: none;
    font-size: 14px;
}

.searchbar_input:focus {
    outline: none;
    border: 0.5px solid #eb00ff;
}

.searchbar_btn {
    padding: 9.745px 22.274px;
    border-radius: 10px;
    color: #fff;
    background: #303030;
    border: none;
    cursor: pointer;
}

.searchbar_btn:hover {
    background-color: #eb00ff;
}

.searchbar--lg {
    width: 35vw;
}

.searchbar--md {
    width: 25vw;
}

.searchbar--sm {
    width: 15vw;
}

@media (max-width: 768px) {
    .searchbar--lg {
        width: 70vw;
    }
    .searchbar--md {
        width: 50vw;
    }
    .searchbar--sm {
        width: 100%;
    }
}