.about__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px;
  font-family: "Manrope", sans-serif;
  backdrop-filter: blur(25px);
}

.about__body {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about__top__subtext {
  font-size: 20px;
  opacity: 0.7;
  font-weight: 600;
}

.about__top__maintext {
  font-size: 2.5rem;
  font-weight: 300;
}

.about__top__maintext span {
  font-weight: 700;
  color: #eb00ff;
}

.about__img {
  width: 50vw;
  height: 350px;
  object-fit: cover;
  margin-top: 30px;
}

.about__content {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .about__body {
    width: 100%;
  }
  .about__img {
    width: 100%;
    height: 250px;
  }
}
