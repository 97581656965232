.viewblog__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 60vw;
}

.view__div-item-title {
    font-weight: 500;
    font-size: 1.3rem;
}

.view__title {
    font-size: 1.1rem;
    margin-top: 16px;
    font-weight: 500;
    opacity: 0.7;
}

.view__tag {
    background-color: #625151;
    padding: 3px 5px;
    margin-right: 15px;
    border-radius: 3px;
}

.view__div-item {
    padding: 3px 5px;
    border-radius: 3px;
}

.view__div-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
}

.view__div {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.view__div-item h2 {
    font-size: 30px !important;
}

.view__div-item h3 {
    font-size: 25px !important;
}

.view__div-item h4 {
    font-size: 20px !important;
}

.view__div-item blockquote {
    font-size: 19px;
    background-color: #161616;
    border-left: 4px solid #bd00ff;
    border-radius: 12px;
    padding: 25px;
}

@media only screen and (max-width: 768px) {
    .viewblog__container {
        width: 90vw;
        padding: 20px;
    }
}