.admin__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    padding: 20px 0;
    backdrop-filter: blur(20px);
}

@media screen and (max-width: 768px) {
    .admin__container {
        height: 100%;
        margin: 0;
    }
}