@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");
option {
  color: black;
}

::-webkit-scrollbar {
  display: none;
}

.chakra-ui-light {
  background-image: url("../images/BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}

.comingsoon_page_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.chat__content__container {
  backdrop-filter: blur(40px);
  border: 0.8px solid #838383;
}

.chat__content {
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .comingsoon_div {
    width: 330px;
    padding: 10px;
  }
}
