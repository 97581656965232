.alert__box {
    width: 400px;
}

.alert {
    display: flex;
    flex-direction: row !important;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .alert__box {
        position: relative;
        top: 0;
        right: 0;
        margin: 10px;
    }
    .alert {
        display: flex;
        flex-direction: row !important;
    }
}