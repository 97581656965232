.header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 80px;
    border-bottom: 1px solid #ffffff5a;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #000;
}

.logo_img {
    width: 125px;
    height: 45px;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .header {
        padding: 0 20px;
    }
}