.navbar_list {
  list-style: none;
  display: inline-flex;
  align-items: center;
  gap: 25px;
}

.temp {
  color: red;
}

.navbar__btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar_list_item {
  padding: 0 10px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  font-family: "Manrope", sans-serif;
}

.navbar_list_item:hover {
  color: #eb00ff;
}

.menu_icon {
  cursor: pointer;
  display: none;
}

.ant-popover-inner {
  background-color: transparent !important;
  backdrop-filter: blur(45px);
  border: 1px solid #ddd;
}

.content__container {
  color: #fff;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content__container__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.content__div {
  display: flex;
  flex-direction: column;
}

.content__name {
  font-size: 14px;
  font-weight: 500;
}

.content__role {
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  background-color: #eb00ff;
  font-size: 11px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}

.content__email {
  font-size: 12px;
  opacity: 0.7;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}

.content__link {
  padding: 5px;
  margin-top: 3px;
  font-size: 14px;
}

.content__link:hover {
  background-color: #eb00ff;
  color: #fff;
  border-radius: 5px;
}

.navbar_avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  border-radius: 25px;
}

.navbar_avatar span {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}

.navbar_avatar:hover {
  color: #fff;
  font-weight: 500;
}

.logout__btn {
  background: transparent;
  margin-left: 9px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}

@media (max-width: 768px) {
  .navbar_list {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 50px;
    right: 0%;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(25px);
    /* background-color: #ac5cb4; */
  }
  .navbar_list_item {
    margin: 0.5rem 0;
    font-size: 18px;
  }
  .menu_icon {
    display: block;
    color: #fff;
    font-size: 30px;
  }
  .mobile-menu-open .navbar_list {
    display: flex;
  }
  .navbar_avatar span {
    margin-right: 10px;
    font-size: 18px;
  }
}

/*ipad air  */

@media (max-width: 1024px) {
  .navbar_list {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 50px;
    right: 0%;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(25px);
    /* background-color: #ac5cb4; */
  }
  .navbar_list_item {
    margin: 0.5rem 0;
    font-size: 18px;
  }
  .menu_icon {
    display: block;
    color: #fff;
    font-size: 30px;
  }
  .mobile-menu-open .navbar_list {
    display: flex;
    z-index: -2;
  }
  .navbar_avatar span {
    margin-right: 10px;
    font-size: 18px;
  }
}
