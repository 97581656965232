.form__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ffffff79;
    padding: 20px 30px;
}

.form__error {
    color: red;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: "Manrope", sans-serif;
}

.form__input__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding: 0px 10px;
    margin: 10px;
    border-radius: 5px;
}

.form__input {
    background: transparent;
    width: 300px;
    font-size: 14px;
    font-weight: 500;
    margin: 10px;
    outline: none;
    border: none;
    color: #000;
    font-family: "Manrope", sans-serif;
}

.form__icon {
    color: #272727;
    opacity: 0.7;
}

.form__input::placeholder {
    font-family: "Manrope", sans-serif;
}

.form__input:focus .form__input__container {
    border: 1px solid #eb00ff;
}

.form__link {
    text-align: center;
    color: #fff;
}

.form__link:hover {
    color: #eb00ff;
}

.form__bottom {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    font-family: "Manrope", sans-serif;
}

.form__bottom-link {
    color: #eb00ff;
}

.form__bottom-link:hover {
    transform: scale(1.09);
    transition: ease-in 0.3ms all;
    color: #fff;
}

.resetform__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-family: "Manrope", sans-serif;
    margin-top: 15px;
    padding: 10px;
}

.resetform__topdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}

.resetform__topdiv h3 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 5px;
}

.resetform__topdiv h4 {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 3px;
}

.resetform__topdiv p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.6;
}

.resetform__form {
    width: 30vw;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 30px;
    border: 1px solid #ffffff79;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.resetform__input {
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    outline: none;
    font-size: 15px;
    color: #000;
    font-family: "Manrope", sans-serif;
}

.resetform__msg__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.resetform__logo__img {
    width: 180px;
}

.resetform__msg {
    color: #fff;
    font-family: "Manrope", sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    width: 50vw;
    text-align: center;
    margin-top: 10px;
}

.resetform__div {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.resetform__div label {
    margin-bottom: 5px;
    opacity: 0.6;
    font-family: "Manrope", sans-serif;
}

.error__msg {
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: "Manrope", sans-serif;
    text-align: center;
}

.form__password__criteria {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    backdrop-filter: blur(20px);
}

.form__password__criteria span {
    /* color: #fff; */
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    font-family: "Manrope", sans-serif;
    list-style: upper-alpha;
}

.met {
    text-decoration: line-through;
    color: blueviolet;
}

@media only screen and (max-width: 768px) {
    .form__container {
        border: none;
    }
    .form__input__container {
        padding: 0px 20px;
    }
    .form__input {
        width: 100%;
        padding: 5px;
        margin: 10px;
        font-size: 15px;
    }
    .resetform__form {
        width: 80%;
    }
    .resetform__msg {
        font-size: 16px;
        width: 100%;
    }
}